import type {MutableRefObject} from 'preact/compat';
import {useCallback, useEffect, useMemo} from 'preact/hooks';

import {AbstractShopJSError} from '~/utils/errors';
import {isRootDomainMatch} from '~/utils/validators';
import {isoWindow} from '~/utils/window';

type MessageHandler<T> = (arg0: T) => void;

interface UseEventListenerParams<T extends {type: string}> {
  allowedOrigins: string[];
  destination?: typeof isoWindow;
  handler: MessageHandler<T>;
  source: MutableRefObject<HTMLIFrameElement | null>;
}

function isSourceOf(event: MessageEvent, source: Window | null) {
  return event.source === source;
}

export function useEventListener<T extends {type: string}>({
  allowedOrigins,
  destination = isoWindow,
  handler,
  source,
}: UseEventListenerParams<T>) {
  const subscriberSet = useMemo(() => new Set<MessageHandler<any>>(), []);

  useEffect(() => {
    subscriberSet.add(handler);

    return () => {
      subscriberSet.delete(handler);
    };
  }, [handler, subscriberSet]);

  const notify = useCallback(
    (event: T) => {
      subscriberSet.forEach((subscriber) => subscriber(event));
    },
    [subscriberSet],
  );

  const eventListener = useCallback(
    (event: MessageEvent) => {
      if (!isSourceOf(event, source?.current?.contentWindow || null)) {
        return;
      }

      if (
        !allowedOrigins.some((origin) =>
          isRootDomainMatch(origin, event.origin),
        )
      ) {
        // eslint-disable-next-line no-console
        console.error('Origin mismatch for message event', event);
        return;
      }

      notify(event.data);
    },
    [allowedOrigins, notify, source],
  );

  const destroy = useCallback(() => {
    destination.removeEventListener('message', eventListener, false);
  }, [destination, eventListener]);

  useEffect(() => {
    destination.addEventListener('message', eventListener, false);

    return () => {
      destroy();
    };
  }, [destination, destroy, eventListener]);

  const waitForMessage = useCallback(
    async (
      messageType: T['type'],
      signal?: AbortSignal,
    ): Promise<Extract<T, {type: T['type']}>> => {
      let handler: MessageHandler<T>;

      const promise = new Promise<Extract<T, {type: T['type']}>>(
        (resolve, reject) => {
          function handleAbort() {
            reject(
              new AbstractShopJSError(
                'Abort signal received',
                'AbortSignalReceivedError',
              ),
            );
          }

          if (signal?.aborted) {
            handleAbort();
          }

          handler = (event) => {
            if (event.type === messageType) {
              signal?.removeEventListener('abort', handleAbort);
              resolve(event as Extract<T, {type: T['type']}>);
            }
          };

          subscriberSet.add(handler);
          signal?.addEventListener('abort', handleAbort);
        },
      ).finally(() => {
        subscriberSet.delete(handler);
      });

      return promise;
    },
    [subscriberSet],
  );

  return {
    destroy,
    waitForMessage,
  };
}

import {createContext} from 'preact';

import type {
  TrekkieAttributes,
  TrekkieDefaultAttribute,
} from '~/types/analytics';

import type {
  AnalyticsData,
  ProduceMonorailEventParams,
  TrackPageImpressionParams,
  TrackUserActionParams,
} from './types';

export interface MonorailContextValue {
  analyticsData: Readonly<AnalyticsData>;
  getTrekkieAttributes: (
    ...attributes: TrekkieDefaultAttribute[]
  ) => Promise<TrekkieAttributes>;
  produceMonorailEvent: (params: ProduceMonorailEventParams) => void;
  trackPageImpression: (params: TrackPageImpressionParams) => Promise<void>;
  trackUserAction: (params: TrackUserActionParams) => void;
}

export const DEFAULT_CONTEXT_VALUE: MonorailContextValue = {
  analyticsData: {},
  getTrekkieAttributes: async () => Promise.resolve({} as TrekkieAttributes),
  produceMonorailEvent: () => {
    throw new Error(
      'Invalid attempt to call produceMonorailEvent outside of context.',
    );
  },
  trackPageImpression: async () => {
    throw new Error(
      'Invalid attempt to call trackPageImpression outside of context.',
    );
  },
  trackUserAction: () => {
    throw new Error(
      'Invalid attempt to call trackUserAction outside of context.',
    );
  },
};

export const MonorailContext = createContext<MonorailContextValue>(
  DEFAULT_CONTEXT_VALUE,
);
